<script setup lang="ts">
const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const { locale } = useI18n();
const shouldRender = props.blok.visibleIn.includes(locale.value);
</script>

<template>
    <StoryblokComponent
        v-for="item in blok.body"
        :key="item._uid"
        :blok="item"
        v-if="shouldRender"
    />
</template>
